.badge {
  text-decoration: none;
  color:var(--primary700);
  display: flex;
  align-items: center;
  border-radius: 1rem;
  background-color: var(--primary50);
  max-width: fit-content;
  white-space: nowrap;
  box-sizing: border-box;
  padding:0.25rem;
  padding-right: 0.625rem;
  .highlight {
    background-color: white;
    border-radius: 1rem;
    box-sizing: border-box;
    padding:0.125rem 0.625rem;
  }
  .text {
    padding-left:0.75rem;
  }
  .arrow {
    margin-left:0.25rem;
  }
}