.socialProof {
  width: 100%;
  height: 18.5rem;
  background:var(--gray100);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  .socialProofInner {
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 90%;
    max-width: 120rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .title {
      color:var(--gray500);
      margin-bottom: 2rem;
      text-align: center;
    }

    .partners {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .partner:hover {
        background-color: var(--gray100);
        .name {
          color:var(--gray900);
        }
        img {
          filter: grayscale(0);
        }
        
      }
      .partner {
        cursor: pointer;
        text-decoration: none;
        transition: background-color 0.3s ease;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding:0.5rem;
        border-radius: 1rem;
        .name {
          margin-left: 0.75rem;
          color:var(--gray500);
          transition: color 0.3s ease;
        }
        img {
          filter: grayscale(1);
        }
      }

      .partner:first-child {
        margin-left:0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .socialProof {
    width: 100%;
    height: auto;
    background:var(--gray100);
    display: flex;
    align-items: center;
    justify-content: center;
  
    .socialProofInner {
      padding-top: 6rem;
      padding-bottom: 6rem;
      width: 90%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        color:var(--gray500);
        margin-bottom: 2rem;
        text-align: center;
      }
  
      .partners {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        .partner:hover {
          background-color: var(--gray100);
          .name {
            color:var(--gray900);
          }
          img {
            filter: grayscale(0);
          }
          
        }
        .partner {
          width: 33%;
          .name {
            display: none;
          }
        }
      }
    }
  }
}