.customButton {
  padding:0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  border:1px solid var(--gray300);
  font-size: 0.875rem;
  font-weight: 500;
  max-width: fit-content;
  box-shadow: var(--shadow-xs);
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 2.375rem;
  box-sizing: border-box;
  background:var(--gray0);
  .icon {
    margin-right: 0.5rem;
    stroke:var(--gray700);
    height: 1.25rem;
  }
  margin-left:0.75rem;
}
.customButton:first-child {
  margin-left: 0;
}


._button_primary{
  background:var(--primary700);

  border:1px solid var(--primary700);
  color:white;

  .icon {
    stroke:white;
  }
}