._features {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 6rem;
  .featuresInner {
    width: 80rem;
    
    .top {
      padding-top: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color:var(--primary700);
        background:var(--primary50);
        border-radius: 1rem;
        padding:0.25rem 0.75rem;
      }
      .headline {
        margin-top: 0.75rem;
      }
      .sub {
        margin-top: 1.25rem;
        color:var(--gray500);
      }
    }
    .feature {
      width: 100%;
      height: 25rem;
      margin-top: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 35rem;
        box-sizing: border-box;
        padding:2rem;

        .name {
          margin-top: 1.4rem;
        }
        .description {
          margin-top: 1rem;
          color:var(--gray500);
        }
        .cta {
          margin-top: 2rem;
          margin-left: 0;
        }
      }
      .right {
        width: 35rem;
        height: 100%;
        background-color: var(--gray100);
        border-radius: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .reversed {
      flex-direction: row-reverse;
    }
  }
}

a {
  text-decoration: none;
  color:inherit;
  max-width: fit-content;
}