._homeInner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80rem;
  position: relative;
  flex-direction: column;
  height: 100vh;

  @keyframes spin {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
  }
  .heroImage {
    animation: 100s spin infinite;
  }
  .left {
    width: 120rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heroText {
      margin-top: 1rem;
    }
    .sub {
      margin-top: 1.5rem;
      color:var(--gray500);
    }

    .buttons {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      .join {
        margin-left: 0;
      }
      .cta {
      }
    }
    
  }
  .right {
    width: 120rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    .heroImage {
      height: 350px;
    }
  }
}

@media (max-width:1024px) {
  ._homeInner {
    display: flex;
    width: 100%;
    min-height: min-content;
    align-items: center;
    justify-content: center;
    @keyframes spin {
      0% {transform:rotate(0deg);}
      100% {transform:rotate(360deg);}
    }
    .heroImage {
      animation: 100s spin infinite;
    }
    .left {
      width: 95%;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .heroText {
        text-align: center;
        font-size: 2.75rem;
        line-height: 3.3rem;
      }
      .sub {
        text-align: center;
        font-size: 1rem;
        line-height: 1.5rem;
      }
  
      .buttons {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        .join {
          margin-left: 0;
        }
        .cta {
        }
      }
      
    }
    .right {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      .heroImage {
        height: 250px;
      }
    }
  }
}