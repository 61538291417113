.testimonials {
  width: 100%;
  height: 41rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  .testimonialsInner {
    width: 80rem;
    height: 29rem;
    background-color: var(--gray100);
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation-name: fade-in;
    animation-duration: 1.5s;
    .left {
      flex:6;
      box-sizing: border-box;
      padding:4rem;
      color:black;

      .subtext {
        margin-top: 1.25rem;
        color:var(--gray500);
        text-align: start;
      }
      .customButton {
        margin-left: 0;
        margin-top: 2.5rem;
      }
      .cta {
        display: flex;
        max-width: fit-content;
        text-decoration: none;
        color:var(--primary700);
        padding:0.5rem 0.6875rem;
        border-radius: 0.3125rem;
        border:1px solid var(--primary700);;
        box-sizing: border-box;
        margin-top: 2.5rem;
      }
    }

    .right {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow:hidden;
      border-radius: 0 0 1.3rem;
      .image {
        position: absolute;
        height: 32rem;
        top:3rem;
        left:3rem;
      }
    }
  }
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width:1024px) {
  .testimonials {
    width: 100%;
    height: auto;
    margin-top: 5rem;
    .testimonialsInner {
      width: 95%;
      height: auto;
      flex-direction: column;
      .left {
        flex:6;
        box-sizing: border-box;
        padding:4rem;
        color:black;
  
        .subtext {
          margin-top: 1.25rem;
          color:var(--gray500);
          text-align: start;
        }
        .customButton {
          margin-left: 0;
          margin-top: 2.5rem;
        }
        .cta {
          display: flex;
          max-width: fit-content;
          text-decoration: none;
          color:var(--primary700);
          padding:0.5rem 0.6875rem;
          border-radius: 0.3125rem;
          border:1px solid var(--primary700);;
          box-sizing: border-box;
          margin-top: 2.5rem;
        }
      }
  
      .right {
        display: none;
      }
    }
  }
}