body {
  margin: 0;
  font-family: "Inter";
  background-color: var(--gray25);
  color: var(--gray900);
  transition: background-color 0.1s;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

:root,
[data-theme="light"] {
  --gray0: #ffffff;
  // gray
  --gray25: #fcfcfd;
  --gray50: #f9fafb;
  --gray100: #f2f4f7;
  --gray200: #e4e7ec;
  --gray300: #d0d5dd;
  --gray400: #98a2b3;
  --gray500: #667085;
  --gray600: #475467;
  --gray700: #344054;
  --gray800: #1d2939;
  --gray900: #101828;

  // primary
  --primary25: #fcfaff;
  --primary50: #e8ecff;
  --primary100: #d1d8ff;
  --primary200: #bac5ff;
  --primary300: #a3b2ff;
  --primary400: #8d9fff;
  --primary500: #768bff;
  --primary600: #5f78ff;
  --primary700: #4865ff;
  --primary800: #3151ff;
  --primary900: #1a3eff;

  // error
  --error25: #fffbfa;
  --error50: #fef3f2;
  --error100: #fee4e2;
  --error200: #fecdca;
  --error300: #fda29b;
  --error400: #f97066;
  --error500: #f04438;
  --error600: #d92d20;
  --error700: #b42318;
  --error800: #912018;
  --error900: #7a271a;

  // warning

  --warning25: #fffcf5;
  --warning50: #fffaeb;
  --warning100: #fef0c7;
  --warning200: #fedf89;
  --warning300: #fec84b;
  --warning400: #fdb022;
  --warning500: #f79009;
  --warning600: #dc6803;
  --warning700: #b54708;
  --warning800: #93370d;
  --warning900: #7a2e0e;

  // success
  --success25: #f6fef9;
  --success50: #ecfdf3;
  --success100: #d1fadf;
  --success200: #a6f4c5;
  --success300: #6ce9a6;
  --success400: #32d583;
  --success500: #12b76a;
  --success600: #039855;
  --success700: #027a48;
  --success800: #05603a;
  --success900: #054f31;

  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);

}

p {
  margin: 0;
}



.page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
}

.pageWrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding:2rem;

  .underTitle {
    color:var(--gray500);
    margin-top: 0.25rem;
  }
}

.Display-xl-sb {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 4.5rem;
  letter-spacing: -0.02em;
}

.Display-md-sb {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.75rem;
  /* identical to box height, or 122% */

  text-align: center;
  letter-spacing: -0.02em;
}
.Display-md-m {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 2.25rem;
  line-height: 2.75rem;

  letter-spacing: -0.02em;
}
.Display-sm-m {
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.Text-md-n {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.Text-md-m {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.Text-xl-n {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
  /* or 150% */
}
.Text-sm-sb {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.Text-md-sb {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
.Text-lg-m {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
}
.Text-lg-n {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.Text-sm-m {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}
.Text-sm-n {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.Display-sm-sb {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.375rem;
}


.Display-lg-sb {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.75rem;
  /* or 125% */

  letter-spacing: -0.02em;
}

.flex {
  display: flex;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}
/* Custom Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 1rem;
}

/* --- Custom Scrollbar */
.popup_container {
  max-width: inherit!important;
}