.metrics {
  width: 100%;
  height:33.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .metricsInner {
    width: 80rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: 1.5rem;
      }

      .description {
        color:var(--gray500);
        margin-top: 1.25rem;
      }
    }

    .stats {
      margin-top: 4rem;
      display: flex;
      align-items: center;

      .stat {
        width: 20rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-right: 1px solid var(--gray200);
        .top {
          color:var(--primary600);
        }
        .bottom {
          margin-top: 0.75rem;
        }
      }
      .stat:last-child {
        border-right: none;
      }
    }

  }
}

@media (max-width: 1024px) {
  .metrics {
    .metricsInner {
      width: 95%;
      padding-top: 6rem;
      padding-bottom: 6rem;
      display: flex;
      align-items: center;
      flex-direction: column;
  
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .title {
          margin-top: 1.5rem;
          text-align: center;
        }
  
        .description {
          color:var(--gray500);
          margin-top: 1.25rem;
          text-align: center;
        }
      }
  
      .stats {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        .stat {
          width: 20rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          border-right: none!important;
          margin-bottom: 1rem;
          .top {
            color:var(--primary600);
          }
          .bottom {
            margin-top: 0.75rem;
          }
        }
        .stat:last-child {
          border-right: none;
        }
      }
  
    }
  }
}