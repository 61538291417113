.homeInner {
  display: flex;
  align-items: center;
  width: 80rem;
  height: calc(100vh - 10rem);
  position: relative;
  .left {
    width: 36rem;
    margin-right: 4rem;

    .heroText {
      margin-top: 1rem;
    }
    .sub {
      margin-top: 1.5rem;
      color:var(--gray500);
    }

    .buttons {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      .join {
        margin-left: 0;
      }
      .cta {
      }
    }
    
  }
  .right {
    flex: 1;
    .heroImage {
      height: 360px;
      width: 100%;
      background-image: url("https://i.imgur.com/uYpeA4y.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      border-radius: 0.6rem;
    }
  }
}


@media (max-width: 1024px) {
  .homeInner {
    width: 99%;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    .left {
      order:2;
      width: 100%;
      margin-right: 0;
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .heroText {
        margin-top: 1rem;
        font-size: 2rem;
        text-align: center;
        line-height: 2.2rem;
      }
      .sub {
        text-align: center;
      }
  
      .buttons {
        margin-top: 3rem;
        display: flex;
        align-items: center;
        .join {
          margin-left: 0;
        }
        .cta {
        }
      }
      
    }
    .right {
      order:1;
      width: 100%;
      display: flex;
      justify-content: center;
      .heroImage {
        height: auto;
        width: 90%;
      }
    }
  }
}