.header {
  width: 100%;
  height: 5rem;
  position:fixed;
  top:0;
  background:var(--gray25);
  box-shadow: var(--shadow-xs);
  z-index:999;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .headerInner {
    width: 80rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .left {
      display: flex;
      align-items: center;
      
      .menu {
        margin-left: 2.5rem;
        a {
          text-decoration: none;
          color:var(--gray500);
          margin-right: 2rem;
        }
        a:hover {
          color:var(--primary700);
        }
        a:last-child {
          margin-right: 0;
        }
      }
    }
    .right {
    }
  }
}

@media (max-width: 1024px) {
  .header {
    .headerInner {
      width: 100%;
      padding: 0 1rem;
      position: relative;
      .left {
        display: flex;
        align-items: center;
        
        .menu {
          margin-left: 2.5rem;
          a {
            text-decoration: none;
            color:var(--gray500);
            margin-right: 2rem;
          }
          a:hover {
            color:var(--primary700);
          }
          a:last-child {
            margin-right: 0;
          }
        }
      }
      
      .right {
        z-index: 1005;
      }
    }
  }
}


.burgerMenu {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--gray50);
  z-index: 999;
  top:0;
  box-sizing: border-box;
  .header {
    position: static;
  }
  .closeButton {
    width: 100%;
    height: 5rem;
    padding:1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
  .inner {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    a {
      font-size: 1.5rem;
      display: flex;
      text-align: right;
      margin-bottom: 1.5rem;
    }
  }
  
}