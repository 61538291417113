.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .footerTop {
    width: 100%;
    height: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray0);
    .inner {
      width: 80rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {

        .sub {
          margin-top: 1rem;
          color:var(--gray500);
        }
      }
      .right {
        display: flex;
        align-items: center;
        a {
          margin-left: 1rem;
        }
      }
    }
  }
  .footerBottom {
    width: 100%;
    height: 26rem;
    background: var(--gray50);
    display: flex;
    align-items: center;
    justify-content: center;
    .inner {
      width: 80rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top {
        width: 100%;
        height: 15rem;
        display: flex;
        align-items: center;
        .left {
          width: 30%;
          height: 100%;
          .sub {
            margin-top: 2rem;
            color:var(--gray500);
          }
        }
        .right {
          width: 70%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          .section:first-child {
            margin-left: 0;
          }
          .section {
            margin-left: 2rem;
            width: 8.75rem;
            .title {
              margin-bottom: 1rem;
            }
            .sub {
              display: flex;
              flex-direction: column;
              a {
                margin-bottom: 0.75rem;
                color:var(--primary700);
                white-space: nowrap;
              }
            }
          }
        }
      }
      .bottom {
        width: 100%;
        height: 3rem;

        .flex {
          margin-top: 2rem;

          .copyright {
            color:var(--gray500);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footerTop {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--gray0);
      padding:2rem 0;
      .inner {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
  
          .sub {
            display: none;
          }
        }
        .right {
          display: flex;
          align-items: center;
          a {
            margin-left: 1rem;
          }
        }
      }
    }
    .footerBottom {
      width: 100%;
      height: 26rem;
      background: var(--gray50);
      display: flex;
      align-items: center;
      justify-content: center;
      .inner {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: fit-content;
          align-items: center;
          justify-content: center;
          .left {
            display: none;
          }
          .right {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .section:first-child {
              margin-left: 0;
            }
            .section:nth-child(4n+4) {
              display: none;
            }
            .section {
              margin-left: 2rem;
              width: 8.75rem;
              .title {
                margin-bottom: 1rem;
              }
              .sub {
                display: flex;
                flex-direction: column;
                a {
                  margin-bottom: 0.75rem;
                  color:var(--primary700);
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .bottom {
          width: 100%;
          height: 3rem;
  
          .flex {
            margin-top: 2rem;
  
            .copyright {
              color:var(--gray500);
            }
          }
        }
      }
    }
  }
}