.grants {
  width: 100%;
  height: 43rem;
  background-color: var(--gray100);
  display: flex;
  align-items: center;
  justify-content: center;
  
  .grantsInner {
    width: 80rem;
    height: 31rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      height: 100%;
      width: 50%;
      padding-left: 2rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .sub {
        margin-top: 1.5rem;
        color:var(--gray500);
      }

      .buttons {
        display: flex;
        align-items: center;
        margin-top: 2.5rem;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .grants {
    height: auto;
    width: 100%;
    margin-top: 5rem;
    .grantsInner {
      width: 95%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      padding:2rem;
      box-sizing: border-box;
      .left {
        height: 100%;
        width: 100%;
        padding-left: 2rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Display-lg-sb {
          font-size: 2rem;
          line-height: 2.2rem;
        }
        .sub {
          margin-top: 1.5rem;
          color:var(--gray500);
        }
  
        .buttons {
          display: flex;
          align-items: center;
          margin-top: 2.5rem;
        }
      }
      .right {
        display: none;
      }
    }
  }
}